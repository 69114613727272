@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  React Draft Wysiwyg */

.rdw-option-wrapper {
  border: none;
  margin: 0px !important;
  padding: 4px !important;
}

.rdw-tags-wrapper {
  width: 200px;
  margin-top: -1px;
  margin-left: auto;
  order: 2;
}

.rdw-custom-editor-wrapper {
  border: 1px solid $gray-border;
  min-height: 114px !important;
  overflow: auto;
  border-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.rdw-custom-toolbar-wrapper {
  border-color: $gray-border;
  margin-bottom: 0px;
  color: black !important;
}

.rdw-custom-wrapper {
  width: 100% !important;
  display: block !important;
}

@media (max-width: 400px) {
  .rdw-custom-wrapper {
    margin-bottom: 25px;
  }
}


