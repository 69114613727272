.titleOnPrint{
  @media print {
    text-decoration: none !important;
  }
}

.textLinkDark{
  color: #DCDCDC;

  &:hover{
    color: #29a9ff;
    transition: 0.3s;
  }
}
