@import "assets/styles/mixins";

.emptyImageHeight {
  height: calc(224px * 0.9);

  @media screen and (min-width: $xs-min-width) {
    height: 224px;
  }
}

.empty {
  margin-top: 64px;
  margin-bottom: 64px;

  :global(.ant-empty-image) {
    @extend .emptyImageHeight;

    margin: 0 auto 24px;
  }
}

svg.emptyImage {
  @extend .emptyImageHeight;

  width: calc(278px * 0.9);

  @media screen and (min-width: $xs-min-width) {
    width: 278px;
  }
}

h4:global(.ant-typography).emptyTitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}

h5:global(.ant-typography).emptyDescription {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;

  &.hasAppendDescription {
    margin-bottom: 24px;
  }

  &:not(.hasAppendDescription) {
    margin-bottom: 0;
  }
}

.emptyAddButton {
  font-weight: 600;
}
