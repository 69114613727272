.changeItem {
  font-size: 10px;
  margin-bottom: 0 !important;

  .changeBefore {
    text-decoration: line-through;
  }

  .changeArrow {
    position: relative;
    bottom: 2px;
  }
}
