.booking-detail {
  .ant-row {
    margin-bottom: 10px;
  }

  .ant-card {
    margin-bottom: 30px;
  }

  .ant-table-wrapper {
    margin-bottom: 20px;
  }

  button {
    min-width: 23%;
    height: 40px;
  }

  .col-overview {
    border-right: 1px solid rgba(0,0,0,.125);
    font-size: 15px;
    padding-right: 20px;
    padding-left: 20px;
    min-height: 150px;

    .col-overview-left {
      font-weight: bolder;
    }

    .col-overview-right {
      text-align: right;
    }
  }

  .col-overview.last {
    border-right: none;
  }
  .divider-overview {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .booking-charge, .booking-payment {
    .ant-table-footer {
      padding: 0px !important;
      font-weight: bolder;
    }
  }

  .detail-spinner > div > .ant-spin .ant-spin-dot {
    top: 10%;
  }

  .ant-page-header-heading-extra {
    width: 50%;
  text-align: right;
}
}

.modal-padding-0 {
  .ant-modal-body{
    padding: 0 !important;
  }
  .ant-card-bordered {
    border: unset !important;
  }
}

@media (max-width: 645px) {
  .col-overview {
    border-right: unset !important;
    overflow: auto;
  }
  .ant-page-header-heading-extra {
    width: 100% !important;
    text-align: right;
  }
  .left {
    .ant-page-header-heading-extra {
      text-align: left !important;
      button {
        margin-left: 0 !important;
      }
    }
  }
}