.socialLoginButton {
  transition: all 0.3s ease;
  text-align: center;
  flex: 1;
  cursor: pointer;

  img {
    margin-bottom: 8px;
    transition: transform 0.3s ease;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    .socialLoginText {
      color: #1890ff;
      text-decoration: underline;
    }
  }
}

.socialLoginText {
  font-size: 12px;
  transition: all 0.3s ease;
} 