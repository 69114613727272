// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN SLIDER */

.ant-slider-with-marks {
  margin-bottom: 19px;
}

.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: $gray-lighten-more;
  border-radius: 2px;
  transition: background-color 0.3s;
}


.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}

.ant-slider-disabled .ant-slider-rail {
  background-color: $gray-lighten-more !important;
}
