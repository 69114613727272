@import "assets/styles/mixins";

.card {
  width: 75%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: $md-min-width) {
    width: 50%;
  }

  @media screen and (min-width: $xxl-min-width) {
    width: 25%;
  }
}

.title {
  font-size: 25px !important;

  @media screen and (min-width: $xl-max-width) {
    font-size: 36px !important;
  }
}

.desc {
  font-size: 10px !important;

  @media screen and (min-width: $sm-min-width) {
    font-size: 13px !important;
  }
}
