@import "assets/styles/mixins";

.formattedMessage {
  // Untuk wrap textnya agar tidak ada scroll horizontal
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  overflow-x: auto;

  margin-top: 10px;
  margin-bottom: 10px;
  color: $black;
  font-family: $base__font-family;
  font-size: #{$base__font-size}px;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-left: 4px;
  border-left-style: solid;
  border-left-color: $gray-darken;
}


:global(.dark) {
  .formattedMessage {
    color: $white;
  }
}

.input :global(.ant-input-group-addon) {
  background-color: var(--background-color);
  padding-left: 0;
  padding-right: 0;
  height: 100%;
  width: 30px
}

.textArea{
  position: relative;
  overflow: hidden;
  border-radius: 6px;

  .sideAccent{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5px;
    background-color: var(--background-color);
    z-index: 1;
  }
}
