@import "assets/styles/mixins";

.percentage:global(.ant-typography) {
  margin-top: 10px;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0 !important;

  @media (min-width: $xl-min-width) {
    font-size: 25px;
  }

  @media (min-width: $xxl-min-width) {
    font-size: 28px;
  }
}

.description:global(.ant-typography) {
  margin-bottom: 0 !important;
  text-align: center;
  font-size: 16px;
  position: relative;
  margin-top: 12px;
  color: #757575;
  z-index: 1;

  @media (min-width: $xl-min-width) {
    font-size: 14px;
  }

  @media (min-width: $xxl-min-width) {
    font-size: 16px;
  }
}
