.tag {
  color: var(--color) !important;
  margin-right: 7px;
  margin-top: 4px;
  margin-bottom: 4px;

  svg {
    color: var(--color);
  }
}

.dark .tag {
  svg {
    color: var(--color);
  }
}
