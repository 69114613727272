.ant-picker {
  min-width: 120px;
}

.ant-modal {
	top: 50px !important;
}

.ant-menu-submenu-popup {
  min-width: 176px;
}

// .ant-collapse-header {
//   padding: 12px 0 6px 0 !important;
//   padding-left: 20px !important;
//   margin-left: 20px !important;
//   line-height: 27px !important;

//   .ant-collapse-arrow {
//     left: 0 !important;
//     top: 25px !important;
//   }
// }

.flex-content-pull-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ant-btn-group {
  flex-wrap: wrap;
}

.ant-btn-success {
  color: #fff;
  background-color: $success;
  border-color: $success;
}

.ant-btn-success:hover {
  color: #fff;
  border-color: $success;
  background-color: lighten($success, 10%);
}

.ant-btn-success:focus {
  color: #fff;
  background-color: $success;
  border-color: $success;
}

.ant-btn-warning {
  color: #fff;
  background-color: $warning;
  border-color: $warning;
}

.ant-btn-warning:hover {
  color: #fff;
  border-color: $warning;
  background-color: lighten($warning, 10%);
}

.ant-btn-warning:focus {
  color: #fff;
  background-color: $warning;
  border-color: $warning;
}

.ant-btn-danger {
  color: #fff;
  background-color: $danger;
  border-color: $danger;
}

.ant-btn-danger:hover {
  color: #fff;
  border-color: $danger;
  background-color: lighten($danger, 10%);
}

.ant-btn-danger:focus {
  color: #fff;
  background-color: $danger;
  border-color: $danger;
}

.row-clickable-table {
  cursor: pointer;
}
.hover-total {
  background-color: #f2f4f8 !important;
  font-weight: bold !important;
}

.dark .hover-total {
  background-color: #424242 !important;
}


th.column-money,
td.column-money {
  text-align: right !important;
}

.column-status-warning {
  background-color: #FFFFEE;
}
.column-status-danger {
  background-color: #FFE6E6;
}
.column-status-info {
  background-color: #BCE8FA;
}
.column-status-success {
  background-color: #3ED0AC;
}
.ant-btn.btn-custom {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn.btn-custom.btn-save-confirmed {
  background-color: #72bf58;
  border-color: #72bf58;
}
.ant-btn.btn-custom.btn-save-unconfirmed {
  background-color: #7F75D5;
  border-color: #7F75D5;
}
.ant-btn.btn-custom.btn-cancelled {
  background-color: #44C0C1;
  border-color: #44C0C1;
}

.ant-btn.btn-custom:hover {
  opacity: 0.8;
}
