.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5000;
}
  

.hidden {
    z-index: -1;
    opacity: 0;
    transition: opacity 1s ease 0.5s, z-index 0.1s ease 1.5s;
}
