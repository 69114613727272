@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  KLEDO */
.button:focus {
  outline: none !important;
}

.button-table {
  margin-top: 30px;
  margin-left: 10px;
  float: right;
}

.button-table-top {
  margin-bottom: 30px;
  margin-left: 10px;
  float: right;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.ant-form-item {
  margin-bottom: 10px !important;

  &.mb-0 {
    margin-bottom: 0 !important;
  }
}

.ant-form-item-label {
  margin-bottom: -3px !important;
}

.hand-row {
  cursor: pointer;
}

.bottom-spacing-5 {
  margin-bottom: 5px;
}

.bottom-spacing-20 {
  margin-bottom: 20px;
}

button.clear-setup {
  background-color: #0190fe;
  color: #fff;
}

button:focus {
  outline: none;
}

.ant-typography-expand {
  color: #0087ff !important;
}

.link-underline {
  a {
    color: #0087ff !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #08f !important;
    }

    &:hover {
      color: #08f !important;
      text-decoration: underline !important;
    }
  }
}

a.text-primary:hover,
a.text-danger:hover {
  text-decoration: underline !important;
}

.btn-default-selected {
  color: #29a9ff;
  border-color: #29a9ff;
}

.title-total {
  font-size: 15px;
  font-weight: bolder;
}

.title-due {
  font-size: 20px;
  font-weight: bolder;
}

.hr-payment,
.hr-total,
.hr-due,
.hr-summary {
  margin-top: 0;
  margin-bottom: 0;
}

.hr-payment {
  border-top: 1px dotted #8c8b8b;
}

.hr-total {
  border-top: 2px solid #000000;
}

.hr-due {
  border-top: 5px double #000000;
}

.no-border {
  border: none !important;
  background: none !important;
}

// table rate

/* Tables
================================== */
.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.Rtable-cell {
  box-sizing: border-box;

  flex-grow: 1;
  width: 100%; // Default to full width
  padding: 0.2em 0.2em;
  overflow: hidden; // Or flex might break
  list-style: none;
  border: solid 1px #e6e6e6;
  height: 80px;
  background: fade(slategrey, 20%);

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 0;
  }
}

.Rtable-cell-header {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%; // Default to full width
  padding: 0.2em 0.2em;
  overflow: hidden; // Or flex might break
  list-style: none;
  //border: solid 1px #eee;
  //height: 70px;
  background: fade(slategrey, 20%);

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 0;
  }
}

table tfoot {
  display: table-row-group;
}

.table-none-border {
  .ant-table-small {
    border: unset !important;

    .footer-total {
      background-color: #e4e9f0;
    }
  }
}

.dark .table-none-border {
  .ant-table-small {
    .footer-total {
      background-color: #1f1f1f;
    }
  }
}

.bg-total {
  background-color: #e4e9f0;
}

.text-right {
  text-align: right !important;
}

.on-cursor {
  cursor: pointer !important;

  &:hover {
    color: #08f !important;
  }
}

.account_watchlist {
  .ant-table-column-title {
    margin-bottom: 0.5rem !important;
    font-size: 1.23rem !important;
    font-weight: 700 !important;
  }

  .ant-table-small {
    border: unset !important;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: 1px dashed #e4e9f0 !important;
    padding: 1rem 0 !important;
  }

  .title {
    margin-bottom: 0.5rem !important;
    font-size: 1.23rem !important;
    font-weight: 700 !important;
  }

  .sub {
    font-size: 1rem !important;
    font-weight: 300 !important;
    margin-bottom: 0 !important;
    color: #b8beca !important;
  }

  .link:hover {
    color: #08f !important;
    text-decoration: underline !important;
  }
}

.modal-variant {
  .ant-modal {
    width: 90vw !important;
  }
}

.report-home {
  .ant-list-item:hover {
    border: 1px solid #08f !important;
    border-radius: 8px;
    color: #08f !important;
    cursor: pointer !important;
    margin: -1px !important;
    margin-bottom: 0px !important;
  }

  .ant-list-item:last-child:hover {
    margin-bottom: -1px !important;
  }

  .ant-list-item.non-link:hover {
    border: 0px solid #e8e8e8 !important;
    border-bottom: 1px solid #e8e8e8 !important;
    color: rgba(0, 0, 0, 0.65) !important;
    cursor: not-allowed !important;
  }
}

.ant-notification-notice-with-icon {
  display: flex;

  .ant-notification-notice-description {
    margin-left: -28px !important;
  }
}

.cursor {
  cursor: pointer;
}

.no-margin-h {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: unset !important;
  }

  tr {
    transition: unset !important;
  }
}

// hide expand icon when row is a footer table
.hover-total .ant-table-row-expand-icon {
  display: none;
}

//Higlight for search text
.highlight {
  color: #f50;
  background-color: transparent;
}

/* Table column sizing
================================== */
.Rtable--7cols > .Rtable-cell {
  width: 14.2857%;
}

.Rtable--7cols > .Rtable-cell-header {
  width: 14.2857%;
}

/* animation fade-in */
.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 645px) {
  .div-responsive {
    overflow: auto;

    .ant-table-wrapper {
      min-width: 700px;
    }
  }
  .ant-card-body {
    padding: 10px !important;
  }
  .ant-drawer-wrapper-body {
    overflow: auto;
  }
  .ant-calendar-range {
    width: 100vw !important;

    .ant-calendar-date-panel {
      display: flex !important;
      flex-direction: column !important;

      .ant-calendar-range-part {
        width: unset !important;
      }
    }
  }
  .modal-variant {
    .ant-modal {
      width: unset !important;
    }
  }
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  height: 30px;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.title-table {
  width: 100%;
}

.text-detail {
  float: right;
}

//Drag drop over table
tr.drop-over-downward td.ant-table-cell {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.ant-table-cell .ant-legacy-form-item {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.ant-select-custom {
  min-width: 350px !important;
}

@media (max-width: 645px) {
  .text-detail {
    float: unset;
    margin-bottom: 10px;
  }
}

@media (max-width: 568px) {
  .title-total {
    font-size: 18px;
  }

  .title-due {
    font-size: 20px;
  }
}

// input height control
input {
  max-height: 32px;
}

// background header table
.bg-header {
  background-color: #fafafa;
}

.bg-header-dark {
  background-color: $dark-3;
}

// hoverable element
.hoverable:hover {
  transition: background-color 0.2s;
  background-color: #fafafa;
}

.dark .hoverable:hover {
  background-color: $dark-3;
}

// stripped items row
.items-body {
  .items-row {
    margin-bottom: 15px;
  }

  .items-row:nth-child(odd) {
    background-color: rgb(228, 233, 240);
  }
}

.dark .items-body {
  .items-row {
    margin-bottom: 0;
  }

  .items-row:nth-child(odd) {
    background-color: black;
  }
}

// Page header full view
.ant-page-header-heading-title {
  white-space: normal !important;
}

.ant-card-head-title {
  white-space: normal !important;
}

.ant-card-head-wrapper {
  justify-content: space-between;
  flex-wrap: wrap;
}

.summary {
  padding-right: 24px;

  .item-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .ant-form-item,
  .ant-radio-group {
    margin-bottom: 0px !important;
  }
}

.summary .row {
  padding: 8px 0px 8px 0px;
  border-bottom: 1px solid #f0f0f0;
}

.summary .row:hover {
  transition: background-color 0.2s;
  background-color: rgb(228, 233, 240);
}

.dark .summary .row:hover {
  background-color: $dark-4;
}

.kit-tabs-bordered {
  .ant-tabs-nav-list {
    padding-left: rem(24);
    padding-right: rem(24);
  }

  &.kit-tabs-bold {
    .ant-tabs-top-bar {
      border-bottom: 1px solid $border;
    }
  }
}

.text-pre-line {
  white-space: pre-line;
}

// Custom padding untuk addon input transaksi
.input-app-currency .ant-input-group-addon {
  padding: 0 5px;
}

// Untuk detail page transaksi
.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.btn-detail-prev {
  position: absolute;
  left: -20px;
  top: 400px;
  z-index: 10;
}

.btn-detail-next {
  position: absolute;
  right: -20px;
  top: 400px;
  z-index: 10;
}

// Untuk popover d
.popover-products-overlay {
  .ant-popover-inner-content {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .ant-list-item {
    padding-left: 0px;
    padding-right: 0px;
    color: white;
  }
}

// prevent UI notification broken if text to long
.ant-notification-notice-description {
  max-width: 80%;
}

#kledopos-action-section a.ant-btn.btn-download {
  padding-top: 5px !important;
}

// Untuk custom darkmode
.bg-dark {
  background-color: $dark-1 !important;
}

.bg-dark-3 {
  background-color: $dark-3 !important;
}

.bg-dark-5 {
  background-color: $dark-5 !important;
}

.bg-light {
  background-color: white !important;
}

.text-dark {
  color: $dark-1 !important;
}

.text-light {
  color: white !important;
}

html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 12px;
  border-left: 1px solid black;
}

html::-webkit-scrollbar-track {
  background-color: white;
}

html::-webkit-scrollbar-thumb {
  border: 3px solid white;
  background-color: darkgray;
  border-radius: 20px;
}

html.dark::-webkit-scrollbar-track {
  background-color: $dark-1;
}

html.dark::-webkit-scrollbar-thumb {
  border: 3px solid $dark-1;
  background-color: $dark-2;
}

html.dark div::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-left: 1px solid black;
}

html.dark div::-webkit-scrollbar-track {
  background-color: $dark-1;
}

html.dark div::-webkit-scrollbar-thumb {
  border: 3px solid $dark-1;
  background-color: $dark-2;
  border-radius: 20px;
}

html.dark .card {
  background-color: $dark-1;
}

.dark .react-multi-email > input {
  background-color: $dark-1;
  color: #cfcfcf;
}

.cursor-pointer:hover {
  cursor: pointer;
}
.product-image-carousel img {
  object-fit: contain;
  border-radius: 4px;
}
.product-image-carousel .photo {
  padding: 4px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.page-header-title {
  font-size: 2.5rem !important;
  font-weight: 500 !important;
  margin-bottom: 0px !important;
  line-height: 1.2 !important;
  word-break: break-word;
}

.ant-page-header .ant-page-header-heading-left {
  min-width: 300px;
}

@import 'bookings/index';
@import 'antd/ant-custom';
