@import "assets/styles/mixins";

.legendText {
  margin: 0;
  padding: 0;

  @media screen and (max-width: $xxl-min-width) {
    font-size: 11px;
  }

  @media screen and (min-width: $xxl-max-width) {
    font-size: 13px;
  }
}
